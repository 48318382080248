import React, { useState, useEffect, useRef, useCallback } from 'react';
import { createPortal } from 'react-dom';
import classNames from 'classnames';
import Loader from './loader';
import Notification from './notification';
import {
  localGet,
  localPatchJSON,
  localPostJSON,
  extractErrorMessage,
} from '../fetch-local';
import { toString, flatMap, sumBy } from 'lodash';
import CopyCode from './CopyCode';
import {
  LoadState,
  RemoteData,
  IndeterminateProgressLoading,
  Success,
  Failure,
} from '../remote-data-types';
import { useDebounce } from './custom-hooks';
import LineItem from './ArtSubmissionApp/LineItem';
import { Color } from '../BrandColor';
import Icon from './Icon';
import LineItemImageViewer from './LineItemImageViewer';
import swal from 'sweetalert';
import tenant from '../tenant';

interface Props {
  canSeeBrandTagging: boolean;
  chargeForOversizedItems: boolean;
  minQuantityLimit: number;
  minPiecesOverridden: boolean;
  url: string;
  displayOrderNameAsLink: boolean;
  orderName: string;
  orderNumber: number;
  sizeBreakdownUrl: string;
  updateSizeBreakdownUrlTemplate: string;
  orderUrl: string;
  salesRepType: string;
  imagePlaceholderUrl: string;
  orderCannotBeRushed: boolean;
  freeDeliveryDate: string;
  salesRepEmail: string;
  showRushAmounts: boolean;
  firstOrangeRushDate: string;
  clearSizesUrl: string;
  canClearSizes: boolean;
}

interface Data {
  lineItems: LineItem[];
}

interface Size {
  id: number | null;
  quantity: number;
  lineItemId: number;
  freeItemQuantityPerSize: number;
  name: string;
  label: string;
  outOfStock: boolean;
  oversized: boolean;
}

interface LineItemData {
  id: number;
  description: string;
  quantity: number;
  price: number | null;
  primaryImageUrl: string;
  secondaryImageUrl?: string | null;
  fulfillmentAttrs: string[];
  freeItemQuantity: number;
  connectedToBirdBank: boolean;
}

type ErrorMessage = string;
type SuccessData = null;

interface LineItem extends LineItemData {
  loadStatus: RemoteData<ErrorMessage, SuccessData>;
}

interface ServerResponse {
  lineItems: (LineItemData & {
    sizes: Size[];
  })[];
}

const loading: IndeterminateProgressLoading = {
  state: LoadState.IndeterminateProgressLoading,
};

const success: Success<SuccessData> = {
  state: LoadState.Success,
  data: null,
};

const error = (message: ErrorMessage): Failure<ErrorMessage> => ({
  state: LoadState.Failure,
  error: message,
});

function SizeBreakdownApp(props: Props) {
  const [lineItems, setLineItems] = useState<LineItem[] | undefined>();
  const [sizes, setSizes] = useState<Size[] | undefined>();
  const [clearSizesErrorMessage, setClearSizesErrorMessage] = useState<
    string[] | null
  >(null);

  const updatedIds = useRef<number[]>([]);
  const checkoutButton = useRef(document.getElementById('checkout-button'));

  const updateSizeBreakdownUrl = useCallback(
    (lineItemId: string): string => {
      return props.updateSizeBreakdownUrlTemplate.replace(
        '__line_item_id__',
        lineItemId
      );
    },
    [props.updateSizeBreakdownUrlTemplate]
  );

  const setLineItem = (
    lineItemId: number,
    func: (lineItem: LineItem) => LineItem
  ): void => {
    setLineItems(olis => {
      if (!olis) return olis;
      return olis.map(oli => {
        if (oli.id === lineItemId) return func(oli);
        return oli;
      });
    });
  };

  const debouncedData = useDebounce(sizes, 1000);

  useEffect(() => {
    console.log('save called');

    if (!lineItems || !sizes) return;

    lineItems.forEach(li => {
      if (!updatedIds.current.includes(li.id)) return;
      updatedIds.current = updatedIds.current.filter(id => id !== li.id);

      setLineItem(li.id, x => ({ ...x, loadStatus: loading }));

      console.log('save happened');
      // Create payload for the patch body and submit the request
      const payload = sizes
        .filter(s => s.lineItemId === li.id)
        .map(size => {
          return {
            id: size.id,
            quantity: size.quantity,
            size: size.name,
            free_items: size.freeItemQuantityPerSize,
          };
        });

      localPatchJSON(updateSizeBreakdownUrl(li.id.toString()), {
        line_item: {
          line_item_sizes_attributes: payload,
        },
      })
        .then(() => {
          setLineItem(li.id, x => ({ ...x, loadStatus: success }));
        })
        .catch(e => {
          extractErrorMessage(e, [
            'There was an issue. Please try re-entering your quantities.',
          ]).then(messages => {
            setLineItem(li.id, x => ({
              ...x,
              loadStatus: error(messages.join(', ')),
            }));
          });
        });
    });
  }, [debouncedData]);

  // Fetch size and line item data on load.
  useEffect(() => {
    localGet(props.url).then((data: ServerResponse) => {
      setLineItems(
        data.lineItems.map(li => ({
          ...li,
          loadStatus: { state: LoadState.NotAsked },
        }))
      );
      setSizes(flatMap(data.lineItems, li => li.sizes));
    });
  }, [props.url]);

  const loadStatusUI = (lineItem: LineItem) => {
    return (
      <>
        {lineItem.loadStatus.state ===
          LoadState.IndeterminateProgressLoading && <Loader />}
        {lineItem.loadStatus.state === LoadState.Success && (
          <Icon type="check-circle" color={Color.Green} />
        )}
        {lineItem.loadStatus.state === LoadState.Failure &&
          lineItem.loadStatus.error && (
            <>
              <Icon type="exclamation-triangle" color={Color.Red} />
              <small className="mls">{lineItem.loadStatus.error}</small>
            </>
          )}
      </>
    );
  };

  if (lineItems === undefined || sizes === undefined) return <Loader />;

  const minQuantity = props.minQuantityLimit;

  const totalOrderQuantity = () => {
    return sumBy(
      sizes.filter(s => s.quantity),
      s => s.quantity
    );
  };

  const quantityNeededToOrder = minQuantity - totalOrderQuantity();

  const pluralizedVerbiage = quantityNeededToOrder === 1 ? 'piece' : 'pieces';

  const displayLessThanMinimumAlert = totalOrderQuantity() < minQuantity;

  const lessThanMinimumAlert = !props.minPiecesOverridden && (
    <Notification type="alert">
      The total quantity can't be less than {minQuantity}. Add at least
      <strong>
        {' '}
        {quantityNeededToOrder} {pluralizedVerbiage}{' '}
      </strong>
      in order to checkout.
    </Notification>
  );

  const hasFreeItems = lineItems.some(lineItem => lineItem.freeItemQuantity);

  const orderHeadline = props.displayOrderNameAsLink ? (
    <h1 className="bb-secondary-headline mvn mrs">
      <a className="headline-link" href={props.orderUrl}>
        {`${props.orderNumber}: ${props.orderName}`}
      </a>
    </h1>
  ) : (
    <h1 className="bb-secondary-headline man">
      {`${props.orderNumber}: ${props.orderName}`}
    </h1>
  );

  const lineItemsView = lineItems.map(lineItem => {
    const lineItemSizes = sizes.filter(s => s.lineItemId === lineItem.id);

    const fulfillmentAttrs = (
      <div className="row">
        <ul className="list-horizontal">
          {lineItem.fulfillmentAttrs.map((attr, i) =>
            attr === 'Brand Tagging' && !props.canSeeBrandTagging ? (
              ''
            ) : (
              <span className="label label--spaced" key={i}>
                {attr}
              </span>
            )
          )}
        </ul>
      </div>
    );

    const productAvailableInOversizedSizes = lineItemSizes.some(
      size => size.oversized
    );

    const price = (
      <>
        <strong>
          Price: {lineItem.price}
          {props.chargeForOversizedItems &&
            productAvailableInOversizedSizes &&
            '*'}
        </strong>
      </>
    );

    const paidQuantity = sumBy(
      sizes.filter(s => s.lineItemId === lineItem.id),
      s => s.quantity
    );

    const freeQuantity = sumBy(
      sizes.filter(s => s.lineItemId === lineItem.id),
      s => s.freeItemQuantityPerSize
    );

    const totalQuantity =
      paidQuantity > 0 || freeQuantity > 0 ? paidQuantity + freeQuantity : 0;

    const quantity = (
      <div>
        Total Quantity: <span className="value mrm">{totalQuantity}</span>
        {loadStatusUI(lineItem)}
      </div>
    );

    const description = (
      <h2 className="bb-secondary-headline">{lineItem.description}</h2>
    );

    const displayUpdateQuantityMessage = (
      <div className="txt-small mbm">
        <em>Need to update your quantity?</em>
        <div data-tooltip="If you decrease your total quantity, your price might increase.">
          <i className="fa fa-info-circle notify mls"></i>
        </div>
      </div>
    );

    const sizeContainer = (
      <>
        {lineItemSizes.map((size, index) => {
          const fieldId = `line-item-${lineItem.id}-size-input-${index}`;
          return (
            <div
              key={`size-container-${lineItem.id}-${index}`}
              className={classNames({
                'size-container': true,
                'hint--bottom': size.outOfStock,
              })}
              aria-label={size.outOfStock ? 'This size is out of stock' : ''}
            >
              <label
                htmlFor={fieldId}
                className={classNames({
                  size__item: true,
                  'size-label': true,
                  'disabled-admin-view': size.outOfStock,
                })}
              >
                {size.label}
              </label>
              <input
                className={classNames({
                  size__item: true,
                  'size-input': true,
                  'disabled-view': size.outOfStock,
                })}
                disabled={lineItem.connectedToBirdBank}
                type="number"
                min="0"
                id={fieldId}
                key={`line-item-size-input-${index}`}
                value={toString(size.quantity || 0)}
                onFocus={e => {
                  e.target.select();
                }}
                onChange={e => {
                  const quantity = parseInt(e.target.value || 0);
                  if (isNaN(quantity)) return;
                  setSizes(oldSizes => {
                    if (!oldSizes) return oldSizes;
                    return sizes.map(s => {
                      if (size.name !== s.name || s.lineItemId !== lineItem.id)
                        return s;
                      return {
                        ...s,
                        quantity,
                      };
                    });
                  });

                  updatedIds.current = [...updatedIds.current, lineItem.id];
                }}
              />
              {hasFreeItems && lineItem.freeItemQuantity > 0 ? (
                <>
                  <div className="size__item size-label size-label-free">
                    Free
                  </div>
                  <input
                    className={classNames({
                      size__item: true,
                      'size-input': true,
                      'disabled-view': size.outOfStock,
                    })}
                    disabled={lineItem.connectedToBirdBank}
                    type="number"
                    key={`line-item-free-size-input-${index}`}
                    value={toString(size.freeItemQuantityPerSize || 0)}
                    onChange={e => {
                      const freeItemQuantityPerSize = parseInt(e.target.value);
                      if (isNaN(freeItemQuantityPerSize)) return;
                      setSizes(oldSizes => {
                        if (!oldSizes) return oldSizes;
                        return sizes.map(s => {
                          if (
                            size.name !== s.name ||
                            s.lineItemId !== lineItem.id
                          )
                            return s;
                          return {
                            ...s,
                            freeItemQuantityPerSize,
                          };
                        });
                      });
                      updatedIds.current = [...updatedIds.current, lineItem.id];
                    }}
                  />
                </>
              ) : (
                ''
              )}
            </div>
          );
        })}
      </>
    );

    const oversizedItemsMessage = productAvailableInOversizedSizes && (
      <p className="mvn">
        <small>
          * - Additional fee of $2 applied per item for sizes 2XL or larger
        </small>
      </p>
    );

    const lineItemsConnectedToBirdBankMessage = lineItem.connectedToBirdBank && (
      <p className="txt-small mvs">
        <em>
          These sizes and quantities were transferred from a Bird Bank and are
          not able to be changed
        </em>
      </p>
    );

    let images = [
      {
        fullSizeUrl: props.imagePlaceholderUrl,
        previewUrl: props.imagePlaceholderUrl,
        processingWarning: '',
      },
    ];

    if (lineItem.primaryImageUrl) {
      images = [
        {
          fullSizeUrl: lineItem.primaryImageUrl,
          previewUrl: lineItem.primaryImageUrl,
          processingWarning: '',
        },
      ];
    }

    if (lineItem.secondaryImageUrl) {
      const x = {
        fullSizeUrl: lineItem.secondaryImageUrl,
        previewUrl: lineItem.secondaryImageUrl,
        processingWarning: '',
      };
      images = [...images, x];
    }

    function clearSizes(id) {
      localPostJSON(props.clearSizesUrl, { line_item_id: lineItem.id })
        .then(() => {
          window.location.reload();
        })
        .catch(error => {
          extractErrorMessage(error, 'Unknown Error').then(message => {
            setClearSizesErrorMessage(message);
          });
        });
    }

    const clearSizesButtonUI = props.canClearSizes && (
      <>
        {clearSizesErrorMessage && (
          <Notification type="alert">
            {clearSizesErrorMessage.length === 1 ? (
              <p className="mvn">{clearSizesErrorMessage[0]}</p>
            ) : (
              <ul className="mvn">
                {clearSizesErrorMessage.map((e, i) => (
                  <li key={i}>{e}</li>
                ))}
              </ul>
            )}
          </Notification>
        )}
        <button
          className="button button--support mrm"
          onClick={async () => {
            if (
              window.confirm(
                `Are you sure you want to clear sizes for the ${lineItem.description.replace(
                  /\s+/g,
                  ' '
                )}?`
              )
            ) {
              clearSizes(lineItem.id);
            }
          }}
        >
          Clear Sizes
        </button>
        <div className="mts">
          {isError && (
            <Notification type="alert">
              There was an issue saving your sizes. Please contact your{' '}
              {props.salesRepType}.
            </Notification>
          )}
          {isSaving && (
            <Notification type="warning">
              Please wait while your size selection is saved.
            </Notification>
          )}
        </div>
      </>
    );

    return (
      <div key={lineItem.id}>
        <div
          className={'grid-row line-item-detail'}
          id={`line-item-detail-${lineItem.id}`}
        >
          <div className="grid-col-5 flex-rows flex-rows--center-h flex-rows--center-v">
            {
              <div className="line-item-image-viewer line-item-image-viewer--full">
                <LineItemImageViewer images={images} />
              </div>
            }
          </div>
          <div className="grid-col-1"></div>
          <div className="grid-col-6">
            {description}
            {clearSizesButtonUI}
            {quantity}
            {price}
            {props.chargeForOversizedItems && oversizedItemsMessage}
            {lineItemsConnectedToBirdBankMessage}
            {!lineItem.connectedToBirdBank && displayUpdateQuantityMessage}
            {fulfillmentAttrs}
            {sizeContainer}
          </div>
        </div>
      </div>
    );
  });

  const isSaving: boolean = lineItems.some(l => l.loadStatus === loading);
  const isError: boolean = lineItems.some(
    l => l.loadStatus.state === LoadState.Failure
  );

  const onClick = e => {
    const dateField: HTMLInputElement | null = document.querySelector(
      '#order_raw_requested_delivery_date'
    );
    if (!dateField) return;
    const earliestFreeDeliveryDate = new Date(props.freeDeliveryDate);
    const { showRushAmounts, salesRepEmail, orderCannotBeRushed } = props;
    let selectedDate = new Date(dateField.value);

    // const selectedDate = new Date(

    let rushDescription;

    let turnaroundDaysMilliseconds =
      earliestFreeDeliveryDate.getTime() - selectedDate.getTime();

    let turnaroundDays = Math.ceil(
      turnaroundDaysMilliseconds / (1000 * 3600 * 24)
    );

    if (selectedDate < earliestFreeDeliveryDate) {
      if (tenant.name === 'uteescorporatesales') {
        rushDescription = '<strong>$75.00</strong>';
      } else if (turnaroundDays === 5) {
        rushDescription =
          '<strong>$2.00</strong> per item with a minimum of <strong>$100.00</strong>.';
      } else {
        rushDescription =
          '<strong>$1.00</strong> per item with a minimum of <strong>$50.00</strong>.';
      }
    }

    if (
      !orderCannotBeRushed &&
      selectedDate < earliestFreeDeliveryDate &&
      tenant.name !== '717ink'
    ) {
      e.preventDefault();

      swal(
        {
          title: 'You have selected rush delivery!',
          text:
            "<p class='mbm'>" +
            'Based on the date you selected you will be charged a rush fee of ' +
            (showRushAmounts ? rushDescription : '') +
            '</p>' +
            '<p><small>' +
            'In the unfortunate and rare case we are unable to accommodate,' +
            ' our Customer Experience Team will reach out to you.' +
            '</small></p>' +
            '<small><strong>Questions?</strong> <br>' +
            'Email: <a href=mailto:' +
            salesRepEmail +
            '>' +
            salesRepEmail +
            '</a><br>',
          type: 'warning',
          showCancelButton: true,
          cancelButtonText: 'Go back',
          confirmButtonColor: '#FE4438',
          confirmButtonText: 'Yes, I accept the rush fee!',
          html: true,
          customClass: 'rush-alert',

          closeOnConfirm: false,
        },
        () => {
          const node = checkoutButton.current;
          if (!node) return;
          const form = node.closest('form');
          if (!form) return;
          form.submit();
        }
      );
    }
  };

  const checkoutButtonUI = (
    <>
      <button
        className="bb-button checkout-delivery-button mrm"
        disabled={isSaving || isError}
        type="submit"
        onClick={onClick}
      >
        Checkout
      </button>
      <a href={props.orderUrl} className="button-pseudo">
        Cancel
      </a>
      <div className="mts">
        {isError && (
          <Notification type="alert">
            There was an issue saving your sizes. Please contact your{' '}
            {props.salesRepType}.
          </Notification>
        )}
        {isSaving && (
          <Notification type="warning">
            Please wait while your size selection is saved.
          </Notification>
        )}
      </div>
    </>
  );

  return (
    <>
      <div className="flex-rows flex-rows--space-b flex-rows--center-v flex-rows--collapse mbl">
        {orderHeadline}
        <CopyCode
          code={props.sizeBreakdownUrl}
          isHidden={true}
          buttonClass="bb-button bb-button--small txt-tight mvs"
          buttonText="Copy link to share"
        />
      </div>
      <div>{displayLessThanMinimumAlert && lessThanMinimumAlert}</div>
      <div>{lineItemsView}</div>
      <div>
        {checkoutButton.current &&
          createPortal(checkoutButtonUI, checkoutButton.current)}
      </div>
    </>
  );
}

export default SizeBreakdownApp;

import React, { Component } from 'react';
import PropTypes from 'prop-types';

class Removable extends Component {
  state = {
    isDeleted: false,
    deletionConfirmed: false,
  };

  static defaultProps = {
    className: '',
    prompt: 'Remove',
    removable: true,
  };

  static propTypes = {
    prefix: PropTypes.string,
    className: PropTypes.string,
    prompt: PropTypes.string,
    id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    removeFunc: PropTypes.func,
    removeLineItemCallBack: PropTypes.func,
  };

  confirmDeletion = event => {
    event.preventDefault();
    if (this.props.removeFunc) {
      (this.props.removeFunc || Function)();
    } else {
      this.props.removeLineItemCallBack();
      this.setState({
        deletionConfirmed: true,
      });
    }
  };

  cancelDeletion = event => {
    event.preventDefault();
    this.setState({ isDeleted: false });
  };

  markDeleted = () => {
    this.setState({
      isDeleted: true,
    });
  };

  render() {
    if (!this.state.deletionConfirmed) {
      let button;
      if (!this.props.removable) button = null;
      else if (!this.state.isDeleted) {
        button = (
          <a
            onClick={this.markDeleted}
            className="remove-button button button--alert button--full"
          >
            <i className="fa fa-close"></i> {this.props.prompt}
          </a>
        );
      } else {
        button = (
          <div
            className="remove-button"
            style={{ width: '100%' }}
            onMouseLeave={this.cancelDeletion}
          >
            <div className="row">
              <div className="col-12">
                <h4 className="mbs quaternary-headline">Are you sure?</h4>
              </div>
            </div>
            <div className="row">
              <div className="col-6">
                <a
                  onClick={this.confirmDeletion}
                  className="button button--success button--full"
                >
                  <i className="fa fa-check"></i> Yes
                </a>
              </div>
              <div className="col-6">
                <a
                  onClick={this.cancelDeletion}
                  className="button button--alert button--full"
                >
                  <i className="fa fa-close"></i> No
                </a>
              </div>
            </div>
          </div>
        );
      }
      return (
        <div className={'react-removable ' + this.props.className}>
          {this.props.children}
          {button}
        </div>
      );
    } else {
      return (
        <span className="hidden">
          <input
            type="hidden"
            name={this.props.prefix + '[id]'}
            value={this.props.id}
          />
          <input
            type="hidden"
            name={this.props.prefix + '[_destroy]'}
            value="1"
          />
        </span>
      );
    }
  }
}

export default Removable;

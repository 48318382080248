import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { extend } from 'lodash';

import LineItemFields from './line-item-fields';
import LineItemPromoProductFields from './line-item-promo-product-fields';
import Removable from './removable';

const GlobalData = window.GlobalData;

class LineItems extends Component {
  static propTypes = {
    prefix: PropTypes.string,
    order: PropTypes.shape({
      id: PropTypes.number.isRequired,
      isPostCheckout: PropTypes.bool.isRequired,
      overrideLineItemLimit: PropTypes.bool.isRequired,
    }).isRequired,
    lineItemMaxQuantity: PropTypes.number,
    items: PropTypes.array,
    products: PropTypes.array,
    art_job_id: PropTypes.number,
    art_submitted: PropTypes.bool,
    brands: PropTypes.array,
    divisions: PropTypes.array,
    all_color_options: PropTypes.array,
    art_job: PropTypes.object,
    validation_errors: PropTypes.arrayOf(PropTypes.string),
    sizes: PropTypes.object,
    globalData: PropTypes.object,
    stateSalesTaxExemptionEditingEnabled: PropTypes.bool,
  };

  constructor(props) {
    super(props);

    let existingItems = this.props.items.map(item => {
      let free_items = item.free_items || 0;
      return { ...item, ...{ free_items } };
    });

    this.state = {
      items:
        existingItems.length > 0 ? existingItems : [this.createNewLineItem()],
      itemCount: existingItems.length || 1,
    };
  }

  componentWillMount() {
    this.prepareGlobalData(this.props.globalData);
    GlobalData.products = this.props.products;
    GlobalData.all_products = this.props.all_products;
    GlobalData.brands = this.props.brands;
    GlobalData.divisions = this.props.divisions;
    GlobalData.all_color_options = this.props.all_color_options;
    GlobalData.sizes = this.props.sizes;
    this.prepareProductOptions();
    this.prepareBrandOptions();
    this.prepareDivisionOptions();
    this.prepareallColorOptions();
  }

  prepareProductOptions() {
    GlobalData.productOptions = GlobalData.products.map((product, index) => {
      return {
        value: product.id.toString(),
        label: product.brand_apparel_style,
        productObject: product,
        outOfStock: product['out_of_stock?'],
        disabled:
          !this.props.outOfStockItemSelectionEnabled &&
          product['out_of_stock?'],
        index: index,
      };
    });
  }

  prepareGlobalData(globalData) {
    extend(GlobalData, globalData);
  }

  prepareBrandOptions() {
    GlobalData.brandOptions = GlobalData.brands.map(brand => {
      return {
        value: brand.id.toString(),
        label: brand.name,
        brandObject: brand,
      };
    });
  }

  prepareDivisionOptions() {
    GlobalData.divisionOptions = GlobalData.divisions.map(division => {
      return {
        value: division.id.toString(),
        label: division.name,
        divisionObject: division,
      };
    });
  }

  prepareallColorOptions() {
    GlobalData.allColorOptions = GlobalData.all_color_options.map(color => {
      return {
        value: color.id.toString(),
        label: color.name,
        allColorObject: color,
      };
    });
  }

  items() {
    let {
      globalData,
      art_submitted,
      art_job_id,
      art_job,
      order,
      outOfStockItemSelectionEnabled,
    } = this.props;

    return this.state.items.map((item, index) => {
      let prefix = `art_job[line_items_attributes][${index}]`;
      let showErrors =
        item.validation_errors && item.validation_errors.length > 0;

      let errors = showErrors ? (
        <div className="notification notification--warning notification--compact stack">
          <ul>
            {item.validation_errors.map((error, i) => (
              <li key={i}>{error}</li>
            ))}
          </ul>
        </div>
      ) : null;

      let key = `item-${index}`;

      let lineItemProps = {
        ...item,
        art_submitted,
        art_job_id,
        key,
        prefix,
        art_job,
        globalData,
        order,
        index,
        areSizeQuantitiesSet: item.size_quantities_present,
        outOfStockItemSelectionEnabled,
        'added_to_bird_bank?': item['added_to_bird_bank?'],
      };

      let promoLineItemProps = {
        key,
        prefix,
        index,
        line_item: item,
        areSizeQuantitiesSet: item.size_quantities_present,
        onChange: this.onUpdatePromoItem,
        stateSalesTaxExemptionEditingEnabled: this.props
          .stateSalesTaxExemptionEditingEnabled,
      };

      let fields = item.promo_product ? (
        <LineItemPromoProductFields {...promoLineItemProps} />
      ) : (
        <LineItemFields {...lineItemProps} />
      );

      let removeableProps = {
        key: `location-${index}`,
        prefix,
        id: item.id,
        className: 'col-4',
        prompt: 'Remove Line Item',
        removable: !item['added_to_bird_bank?'],
        removeLineItemCallBack: this.removeItem,
      };

      return (
        <Removable {...removeableProps}>
          {errors}
          {fields}
        </Removable>
      );
    });
  }

  createNewLineItem() {
    let { art_job, order } = this.props;
    let individual_ship =
      (
        GlobalData['currentUserRoles'] ||
        this.props.globalData['currentUserRoles'] ||
        []
      ).indexOf('live_sales_coordinator') !== -1;

    let bird_bank =
      (
        GlobalData['currentUserRoles'] ||
        this.props.globalData['currentUserRoles'] ||
        []
      ).indexOf('live_sales_coordinator') !== -1;

    let none = !individual_ship;

    let heat_tagging = order.utees || !order.corporate;

    if (art_job && art_job.promo_product) {
      return {
        free_items: 0,
        promo_product: {},
        individual_ship,
        bird_bank,
      };
    } else {
      return {
        free_items: 0,
        individual_ship,
        none,
        bird_bank,
        heat_tagging,
      };
    }
  }

  addItem = event => {
    event.preventDefault();
    this.setState(function(previousState) {
      let { items } = previousState;
      return {
        items: [...items, this.createNewLineItem()],
        itemCount: this.state.itemCount + 1,
      };
    });
  };

  removeItem = () => {
    this.setState({
      itemCount: this.state.itemCount - 1,
    });
  };

  onUpdatePromoItem = (index, line_item) => {
    this.setState(function(previousState) {
      return {
        items: previousState.items.map((item, i) =>
          index === i
            ? {
                ...item,
                ...line_item,
              }
            : item
        ),
      };
    });
  };

  render() {
    const { order, lineItemMaxQuantity } = this.props;
    let { itemCount } = this.state;

    let canAddAnotherLineItem =
      order.overrideLineItemLimit || itemCount < lineItemMaxQuantity;

    let overLineItemLimitErrorMessage = canAddAnotherLineItem ? null : (
      <div className="notification notification--warning">
        <span>
          {order.utees
            ? `You've reached the line item limit of ${lineItemMaxQuantity} items.`
            : `You must override the line item limit to add more than ${lineItemMaxQuantity} items.`}
        </span>
      </div>
    );

    return (
      <div className="locations row row-dynamic">
        {this.items()}
        <div className="col-4">
          {overLineItemLimitErrorMessage}
          <button
            className="button button--large button--full button--secondary mbm"
            onClick={this.addItem}
            disabled={!canAddAnotherLineItem}
            title="Add another item"
          >
            <i className="fa fa-plus" /> Add Another Item
          </button>
        </div>
      </div>
    );
  }
}

export default LineItems;

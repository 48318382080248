import React, { useState } from 'react';
import ReviewStatusButton from './ReviewStatusButton';
import Modal from 'react-modal';
import affinityPng from '../images/affinity.png';

export default function AffinityReviewStatus(props) {
  const { canEdit, status, reviews, mostRecentUpdate } = props;

  const [isOpen, toggleReview] = useState(false);

  const closeReview = e => {
    e.preventDefault();
    toggleReview(false);
  };

  function getOccurrences(array, value) {
    return array.filter(v => v === value).length;
  }
  const approvals = getOccurrences(reviews, 'approved');

  const statusBar = reviews.map((review, i) => (
    <div key={i}>
      <span className={`review-status-indicator ${review}`} />
      <div className="review-status-label">{review}</div>
    </div>
  ));

  const statusLastUpdate = mostRecentUpdate ? (
    <p>
      <strong>Last Affinity Update: </strong>
      <span>{mostRecentUpdate}</span>
    </p>
  ) : null;

  const header =
    status === 'none submitted'
      ? 'Affinity : No review has been submitted'
      : `Affinity : Review ${status}`;

  if (isOpen) {
    return (
      <React.Fragment>
        <div className="pts pbs">
          <ReviewStatusButton
            canEdit={canEdit}
            status={status}
            toggleReview={toggleReview}
          />
        </div>

        <Modal
          isOpen={isOpen}
          className="react-modal"
          overlayClassName="react-modal-overlay"
          contentLabel="Affinity Review Status Modal"
          closeTimeoutMS={200}
          shouldCloseOnEsc={true}
          shouldCloseOnOverlayClick={true}
          onRequestClose={closeReview}
        >
          <div className="well">
            <div className="media media--center mbm">
              <div className="media__obj mrs">
                <img src={affinityPng} height="22" width="22" alt="" />
              </div>

              <div className="media__body">
                <h2 className="tertiary-headline mbn">{header}</h2>
              </div>

              <button
                className="close-button close-button-small"
                type="button"
                onClick={closeReview}
              >
                Close
              </button>
            </div>

            <div className="affinity-review-status-bar">{statusBar}</div>

            <div className="txt-small">
              <p>
                {approvals} out of {reviews.length} designs have been approved.
              </p>
              {statusLastUpdate}
            </div>
          </div>
        </Modal>
      </React.Fragment>
    );
  } else {
    return (
      <div className="pts pbs">
        <ReviewStatusButton
          canEdit={canEdit}
          status={status}
          toggleReview={toggleReview}
        />
      </div>
    );
  }
}

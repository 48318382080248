import React, { Component } from 'react';
import PropTypes from 'prop-types';
import EditInPlace from './EditInPlace';

class PrintColorFields extends Component {

  static propTypes = {
    locations_line_items: PropTypes.arrayOf(PropTypes.object),
    prefix: PropTypes.string
  }

  humanize(s) {
    let r = s.charAt(0).toUpperCase() + s.slice(1);
    return r.replace(/\-|_/, ' ');
  }

  fields() {
    let _this = this;
    let { locations_line_items } = this.props;

    return locations_line_items.map((locations_line_item, index) => {
      const hiddenName =
        `${_this.props.prefix}[locations_line_items_attributes][${index}][id]`;

      const editInPlacePrefix =
        `${_this.props.prefix}[locations_line_items_attributes][${index}]`;

      return (
        <span
          style={{ position:'relative' }}
          key={`locations-line-item-${locations_line_item.id}`}
        >
          <p className="mbs pts">
            {_this.humanize(locations_line_item.location.location)}
          </p>

          <input
            type="hidden"
            name={hiddenName}
            value={locations_line_item.id}
          />

          <ul className="list-plain location-colors-list pll">
            <EditInPlace
              prefix={editInPlacePrefix}
              fieldName="print_color_pms_codes"
              defaultValue={locations_line_item.print_color_pms_codes}
              valueRenderer= "PrintColorsRenderer"
              valueRendererProps={{
                order_id: _this.props.order_id,
                decoration_type: locations_line_item.location.decoration_type
              }}
              fieldRenderer= "ColorSelect"
              className="inline-block"
              fieldRendererProps= {{
                allowCustom: true,
                decorationType: locations_line_item.location.decoration_type
              }}
            />
          </ul>
        </span>
      );
    });
  }

  render() {
    return (
      <div className="locations-line-items-fields">
        {this.fields()}
      </div>
    );
  }
}

export default PrintColorFields;

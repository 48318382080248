import React from 'react';
import PropTypes from 'prop-types';
import { joinJSX } from '../reactUtilities';
import IconSvg from './IconSvg';
import { Color } from './../BrandColor';

class Collapsible extends React.Component {
  static displayName = 'Collapsible';

  static propTypes = {
    header: PropTypes.string,
    className: PropTypes.string,
    startCollapsed: PropTypes.bool,
    notes: PropTypes.array,
  };

  static defaultProps = {
    className: '',
    startCollapsed: false,
  };

  state = {
    collapsed: this.props.startCollapsed,
  };

  _toggle = () => {
    this.setState({
      collapsed: !this.state.collapsed,
    });
  };

  _handleClick = event => {
    event.preventDefault();
    this._toggle();
  };

  render() {
    let iconClass, bodyClass;
    if (this.state.collapsed) {
      iconClass = 'fa-caret-left';
      bodyClass = 'collapsed';
    } else {
      iconClass = 'fa-caret-down';
    }
    return (
      <div className={'pam collapsible ' + this.props.className}>
        <a
          href="#"
          onClick={this._handleClick}
          className="collapsible-title purchasing-order-notes-header"
        >
          <i className={'pull-right fa ' + iconClass}></i>
          <h5 className="mvs flex-rows flex-rows--center-v">
            <IconSvg icon="warning" color={Color.Yellow} />
            {this.props.header}
          </h5>
        </a>
        <div className={'txt-small collapsible-body ' + bodyClass}>
          {joinJSX(this.props.notes, <br />)}
        </div>
      </div>
    );
  }
}

export default Collapsible;

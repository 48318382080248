import React, { useState, useEffect } from 'react';
import Select from 'react-select';

interface DataValue {
  value: number;
  formatted: string;
}

interface Warehouse {
  id: number;
  label: string;
  supplierId: number;
  inventory?: DataValue;
}

interface Option extends Warehouse {
  value: string;
}

interface OptionProps {
  children: string;
  option: Option;
  className: string;
  isDisabled: boolean;
  isFocused: boolean;
  isSelected: boolean;
  onFocus: (a: Option, any) => any;
  onSelect: (a: Option, any) => any;
}

const WarehouseOption = (props: OptionProps) => {
  const handleMouseDown = e => {
    e.preventDefault();
    e.stopPropagation();
    props.onSelect(props.option, e);
  };

  const handleMouseEnter = e => {
    props.onFocus(props.option, e);
  };

  const handleMouseMove = e => {
    if (props.isFocused) return;
    props.onFocus(props.option, e);
  };

  return (
    <div
      className={props.className + ' warehouse-dropdown-item phm pvs'}
      onMouseDown={handleMouseDown}
      onMouseEnter={handleMouseEnter}
      onMouseMove={handleMouseMove}
      title={props.option.label}
      role="button"
      tabIndex={-1}
    >
      {props.children}
      {props.option.inventory && (
        <span className="txt-success txt-right">
          {props.option.inventory.formatted}
        </span>
      )}
    </div>
  );
};

interface ValueProps {
  children: JSX.Element;
  placeholder: string;
  value: Option;
}

const WarehouseValue = (props: ValueProps) => {
  return (
    <div className="Select-value" title={props.value.label}>
      <span className="Select-value-label">{props.children}</span>
    </div>
  );
};

interface Props {
  formId: string;
  name: string;
  initialSelectedSupplierId: number;
  initialSelectedWarehouseId: number;
  warehouses: Warehouse[];
}

const SupplierSplitWarehouseSelect = ({
  formId,
  name,
  initialSelectedSupplierId,
  initialSelectedWarehouseId,
  ...props
}: Props) => {
  const [value, setValue] = useState<string | null>(null);
  const [selectedSupplier, setSelectedSupplier] = useState<string | null>(
    initialSelectedSupplierId ? initialSelectedSupplierId.toString() : null
  );

  useEffect(() => {
    setValue(
      initialSelectedWarehouseId ? initialSelectedWarehouseId.toString() : null
    );
  }, [setValue, initialSelectedWarehouseId]);

  useEffect(() => {
    const handleEvent = e => {
      if (e.detail.formId !== formId) return;
      setSelectedSupplier(
        e.detail.supplierId ? e.detail.supplierId.toString() : null
      );
    };

    window.addEventListener('supplierSet', handleEvent);

    return () => {
      window.removeEventListener('supplierSet', handleEvent);
    };
  }, [formId]);

  const warehouses = props.warehouses.filter(
    w => w.supplierId.toString() === selectedSupplier
  );

  const selectId = `${formId}-warehouse`;

  return (
    <div className="section txt-left" id={selectId}>
      <Select
        name={name}
        onChange={e => {
          const value = e ? e.value : e;
          setValue(value);
        }}
        optionComponent={WarehouseOption}
        options={warehouses.map(warehouse => {
          return { ...warehouse, value: warehouse.id, label: warehouse.label };
        })}
        placeholder={'Select Warehouse'}
        value={value}
        valueComponent={WarehouseValue}
      />
    </div>
  );
};

export default SupplierSplitWarehouseSelect;

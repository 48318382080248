import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';
import { invert, values, map } from 'lodash';
import { localGet } from '../fetch-local';

const GlobalData = window.GlobalData;

class CustomItemSelect extends Component {
  static propTypes = {
    prefix: PropTypes.string,
    rolesCanEditPostCheckout: PropTypes.bool,
    rolesCanEditPreCheckout: PropTypes.bool,
    product: PropTypes.object,
    order: PropTypes.shape({
      id: PropTypes.number.isRequired,
      isPostCheckout: PropTypes.bool.isRequired,
      formIsExposable: PropTypes.bool,
    }).isRequired,
    color: PropTypes.object,
  };

  constructor(props) {
    super(props);

    let { product, color } = this.props;

    let customProps = product => {
      if (!product) return {};

      let { brand, name, style, base_price, weight, custom_item } = product;

      return {
        brand_name: brand ? brand.name : null,
        brand_id: brand ? brand.id.toString() : null,
        name: name,
        style: style,
        base_price: base_price,
        weight: weight,
        custom_item: custom_item,
        custom_color: color ? color.name : null,
        color: color
          ? {
              value: color.id,
              label: color.name,
            }
          : null,
        color_id: color ? color.id.toString() : null,
      };
    };

    this.state = {
      ...customProps(product),
      sizes: this.props.product ? this.props.product.selected_sizes_ids : [],
    };
  }

  handleBrandSelect = brand_id => {
    this.setState({ brand_id });
  };

  handleColorSelect = color_id => {
    this.setState({ color_id });
  };

  handleWeightSelect = event => {
    let weight = event.target.value;
    this.setState({ weight });
  };

  handleSizeSelect = (id, event) => {
    let checked = event.target.checked;

    let removeSizes = this.state.sizes.reduce((result, size) => {
      if (size === id && !checked) {
        return result;
      } else {
        return [...result, size];
      }
    }, []);

    let sizes =
      checked && !this.state.sizes.includes()
        ? [...removeSizes, id]
        : removeSizes;

    this.setState({ sizes });
  };

  displayHiddenSizes() {
    let { prefix } = this.props;
    let lookup = invert(
      values(GlobalData.sizes).reduce(
        (m, o) => ({
          ...m,
          ...o,
        }),
        {}
      )
    );

    return this.state.sizes.map((size, i) => (
      <input
        type="hidden"
        key={i}
        name={prefix + '[product_attributes][selected_sizes][]'}
        value={lookup[size]}
      />
    ));
  }

  displaySizes() {
    let _this = this;
    return map(GlobalData.sizes, function(sizes, category) {
      let sizes_array = map(sizes, function(id, size) {
        const name = `${
          _this.props.prefix
        }[product_attributes][selected_sizes][]`;

        return (
          <li key={'size-' + id}>
            <label>
              <input
                type="checkbox"
                name={name}
                value={size}
                checked={_this.state.sizes.includes(id)}
                onChange={_this.handleSizeSelect.bind(_this, id)}
              />
              <span> {_this.humanize(size)}</span>
            </label>
          </li>
        );
      });
      return (
        <div key={'size-group-' + category}>
          <h3>{_this.humanize(category)}</h3>
          <ul className="list-plain">{sizes_array}</ul>
        </div>
      );
    });
  }

  money(n) {
    return '$' + parseFloat(n).toFixed(2);
  }

  humanize(s) {
    if (!s) return '';
    let r = s.charAt(0).toUpperCase() + s.slice(1);
    return r.replace(/-|_/, ' ');
  }

  hiddenCustomItemFields() {
    if (!this.state.custom_item) {
      return null;
    }

    return (
      <div>
        <input
          type="hidden"
          name={this.props.prefix + '[product_attributes][brand_id]'}
          value={this.state.brand_id}
        />

        <input
          type="hidden"
          name={this.props.prefix + '[product_attributes][name]'}
          value={this.state.name}
        />

        <input
          type="hidden"
          name={this.props.prefix + '[product_attributes][style]'}
          value={this.state.style}
        />

        <input
          type="hidden"
          name={this.props.prefix + '[product_attributes][base_price]'}
          value={this.state.base_price}
        />

        <input
          type="hidden"
          name={this.props.prefix + '[product_attributes][weight]'}
          value={this.state.weight}
        />

        <input
          type="hidden"
          name={this.props.prefix + '[color_id]'}
          value={this.state.color_id}
        />

        {this.displayHiddenSizes()}
      </div>
    );
  }

  render() {
    let customItemFields = (
      <div>
        <h4>Custom Product</h4>

        <input
          type="hidden"
          name={this.props.prefix + '[custom_item]'}
          value={this.state.custom_item}
        />

        <Select
          name={this.props.prefix + '[product_attributes][brand_id]'}
          inputProps={{ name: this.props.prefix + '[brand_id]' }}
          options={GlobalData.brandOptions}
          value={this.state.brand_id}
          onChange={this.handleBrandSelect}
          matchProp="label"
          className="brand-select"
          simpleValue={true}
          placeholder="Select a Brand"
        />

        <div>
          <label htmlFor="style">Name</label>
          <input
            type="text"
            name={this.props.prefix + '[product_attributes][name]'}
            defaultValue={this.state.name}
            placeholder="Name"
          />
        </div>

        <div>
          <label htmlFor="style">Style Number</label>
          <input
            type="text"
            name={this.props.prefix + '[product_attributes][style]'}
            defaultValue={this.state.style}
            placeholder="Style Number"
          />
        </div>

        <div>
          <label htmlFor="style">Price</label>
          <input
            type="text"
            name={this.props.prefix + '[product_attributes][base_price]'}
            defaultValue={this.state.base_price}
            placeholder="Price"
          />
        </div>

        <label htmlFor="weight" className="label-select">
          <span className="is-hidden-visually">Shipping Weight</span>
          <select
            name={this.props.prefix + '[product_attributes][weight]'}
            value={this.state.weight}
            onChange={this.handleWeightSelect}
          >
            <option value="">Shipping Weight</option>
            <option value="shipping_light">Light</option>
            <option value="shipping_medium">Medium</option>
            <option value="shipping_heavy">Heavy</option>
            <option value="shipping_super_heavy">Super Heavy</option>
          </select>
        </label>

        <Select
          name={this.props.prefix + '[color_id]'}
          inputProps={{ name: this.props.prefix + '[color_text]' }}
          options={GlobalData.allColorOptions}
          value={this.state.color_id}
          onChange={this.handleColorSelect}
          matchProp="label"
          className="color-select"
          simpleValue={true}
          placeholder="Select a Color"
        />

        <h4>Item Sizes</h4>

        {this.displaySizes()}
      </div>
    );

    let canEdit =
      (!this.props.order.isPostCheckout &&
        this.props.rolesCanEditPreCheckout) ||
      this.props.rolesCanEditPostCheckout;

    let itemDetails = canEdit ? (
      customItemFields
    ) : (
      <div>
        <p>
          <strong>Brand:</strong> {this.state.brand_name}{' '}
        </p>
        <p>
          <strong>Name:</strong> {this.state.name}{' '}
        </p>
        <p>
          <strong>Style Number:</strong> {this.state.style}{' '}
        </p>
        <p>
          <strong>Price:</strong> {this.money(this.state.base_price)}{' '}
        </p>
        <p>
          <strong>Weight:</strong> {this.humanize(this.state.weight)}{' '}
        </p>
        {this.hiddenCustomItemFields()}
      </div>
    );

    return <div>{itemDetails}</div>;
  }
}

export default CustomItemSelect;

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import JobForm from './job-form';
import ItemForm from './item-form';
import Item from './item';
import { reject } from 'lodash';
import { localGet, localPostJSON } from '../fetch-local';

export default class PriceCalculator extends Component {
  constructor(props) {
    super(props);
    this.state = {
      job: props.job || this.newJob(),
      items: [],
      topProducts: [],
      newItem: this.newItem(),
      saveButtonEnabled: true,
      archiveButtonEnabled: true,
      hideCommissioningData: !props.fullAccess,
    };
  }

  static propTypes = {
    job: PropTypes.object,
    items: PropTypes.arrayOf(PropTypes.object),
    fullAccess: PropTypes.bool,
    commissionLevel: PropTypes.number,
  };

  static defaultProps = {
    items: [],
    fullAccess: false,
  };

  newJob = () => ({
    isReorder: false,
    locations: [0, 0, 0, 0],
    hasGreekRoyalty: false,
    hasCollegeRoyalty: false,
    printColorChange: false,
    rush: 0,
    rushShipping: 0,
    jobNumber: 0,
    heatPressType: this.props.heatPressLocations,
    embroideryLocations: [],
    commissionLevel: this.props.commissionLevel,
  });

  newItem = () => ({
    price: 0,
    quantity: 0,
    shippingType: '',
    description: '',
    isBagAndTag: false,
    isIndividualPayment: false,
    isIndividualShip: false,
    isHeatTagging: false,
    freeShirtsQuantity: 0,
    actual: null,
  });

  componentWillMount() {
    if (this.props.items && this.props.items.length > 0) {
      this.addItems(this.props.items);
    }
  }

  componentDidMount() {
    localGet('/api/v1/products/available').then(data => {
      this.setState({
        topProducts: data.product,
      });
    });
  }

  toggleCommission = event => {
    event.preventDefault();
    this.setState({ hideCommissioningData: !this.state.hideCommissioningData });
  };

  payload = (job, items) => {
    let itemsPayload = items.map(item => {
      let payload = {
        quantity: parseInt(item.quantity),
        isBagAndTag: item.isBagAndTag,
        isBirdBank: item.isBirdBank,
        isIndividualPayment: item.isIndividualPayment,
        isIndividualShip: item.isIndividualShip,
        isHeatTagging: item.isHeatTagging,
        freeShirtsQuantity: parseInt(item.freeShirtsQuantity),
        actual: parseFloat(item.actual),
        discount: item.discount,
      };

      if (!item.productId) {
        payload.price = parseFloat(item.price);
        payload.shippingType = item.shippingType;
        payload.description = item.description;
      } else {
        payload.productId = parseInt(item.productId);
      }

      return payload;
    });

    const commissionLevel =
      job.commissionLevel === undefined
        ? undefined
        : parseFloat(job.commissionLevel);

    let jobPayload = {
      ...job,
      locations: job.locations.map(l => parseInt(l)),
      rush: parseFloat(job.rush),
      rushShipping: parseFloat(job.rushShipping),
      commissionLevel,
    };

    return {
      job: jobPayload,
      items: itemsPayload,
    };
  };

  fetchArchive = (job, items) => {
    const url = '/api/v1/price_calculation/archive';
    const body = this.payload(job, items);
    return localPostJSON(url, body);
  };

  fetchCalculate = (job, items) => {
    const url = '/api/v1/price_calculation';
    const body = this.payload(job, items);
    return localPostJSON(url, body);
  };

  updateJob = updatedJob => {
    let items = this.state.items;
    let newItems = this.fetchCalculate(updatedJob, items);
    if (items.length > 0) {
      newItems.then(
        function(data) {
          this.setState({ items: data.items });
          this.setState({ job: updatedJob });
        }.bind(this)
      );
    } else {
      this.setState({ items: [] });
      this.setState({ job: updatedJob });
    }
  };

  updateNewItem = updatedItem => {
    this.setState({ newItem: updatedItem });
  };

  archive = () => {
    let job = this.state.job;
    let items = this.state.items;
    this.setState({ archiveButtonEnabled: false });
    this.fetchArchive(job, items)
      .then(
        function(data) {
          if (data.successRedirectUrl) {
            window.location.href = data.successRedirectUrl;
          }
        }.bind(this)
      )
      .finally(
        function() {
          this.setState({ archiveButtonEnabled: true });
        }.bind(this)
      );
  };

  addItems = newItems => {
    let job = this.state.job;
    let items = this.state.items.concat(newItems);
    let newItemsPricing = this.fetchCalculate(job, items);
    newItemsPricing.then(
      function(data) {
        this.setState({ items: data.items });
      }.bind(this)
    );
  };

  addItem = newItem => {
    this.addItems([newItem]);
  };

  updateItem = updatedItem => {
    let job = this.state.job,
      items = this.state.items.map(
        function(item) {
          if (item.id === updatedItem.id) {
            return updatedItem;
          } else {
            return item;
          }
        }.bind(this)
      );

    // NOTE: this is fix 1-way binding issue on invalid data
    if (updatedItem.actual.toString().match(/^[0-9]+(\.[0-9]+)?$/)) {
      let newItems = this.fetchCalculate(job, items);
      newItems.then(
        function(data) {
          this.setState({ items: data.items });
        }.bind(this)
      );
    } else {
      this.setState({ items: items });
    }
  };

  removeItem = removedItem => {
    let job = this.state.job;
    let items = reject(this.state.items, function(item) {
      return item.id == removedItem.id;
    });
    let newItems = this.fetchCalculate(job, items);
    newItems.then(
      function(data) {
        this.setState({ items: data.items });
      }.bind(this)
    );
  };

  totalPrice = () => {
    let total = this.state.items.reduce(function(sum, item) {
      let itemTotal = parseFloat(item.actual || 0) * item.quantity;
      return sum + itemTotal;
    }, 0.0);
    return total;
  };

  archiveButton = () => {
    if (this.props.fullAccess) {
      return (
        <button
          onClick={this.archive}
          className="button button--large button--full-res mrm"
          disabled={!this.state.archiveButtonEnabled}
        >
          Save Estimate
        </button>
      );
    }
  };

  render = () => {
    const job = this.state.job;
    const topProducts = this.state.topProducts;
    let itemNodes = this.state.items.map((item, index) => (
      <Item
        item={item}
        key={'pricing-item-' + index}
        products={topProducts}
        onUpdate={this.updateItem}
        onRemove={this.removeItem}
        fullAccess={this.props.fullAccess}
        hideCommissioningData={this.state.hideCommissioningData}
      />
    ));

    if (itemNodes.length == 0) {
      itemNodes = (
        <tbody>
          <tr>
            <td colSpan="7">
              <div className="empty-text mvl">No Items</div>
            </td>
          </tr>
        </tbody>
      );
    }

    return (
      <div className="l-section">
        <div className="job-panel">
          <JobForm
            job={job}
            onUpdateJob={this.updateJob}
            allowCommissionUpdate={this.props.fullAccess}
            showCommissionLevel={!this.state.hideCommissioningData}
          />
        </div>
        <div className="item-panel">
          <ItemForm
            item={this.state.newItem}
            products={topProducts}
            onSubmit={this.addItem}
            onUpdate={this.updateNewItem}
            hideCommissioningData={this.state.hideCommissioningData}
            fullAccess={this.props.fullAccess}
          />
        </div>

        <table className="table--header-new table--responsive">
          <thead>
            <tr>
              <th>Item</th>
              <th>Suggested</th>
              <th>Actual</th>
              <th>{this.state.hideCommissioningData ? '' : 'Commission'}</th>
              <th>{this.state.hideCommissioningData ? '' : 'Discount'}</th>
              <th>Total Sale</th>
              <th />
            </tr>
          </thead>
          {itemNodes}
        </table>

        <div className="pricing-total l-right">
          Total: ${this.totalPrice().toFixed(2)}
        </div>

        {this.archiveButton()}
        <a href="#" onClick={this.toggleCommission} className="button-pseudo">
          {this.state.hideCommissioningData
            ? 'Show detailed information'
            : 'Hide detailed information'}
        </a>
      </div>
    );
  };
}

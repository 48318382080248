import React, { useEffect, useRef, useState } from 'react';
import classNames from 'classnames';
import ItemImage from '../ItemImage';
import SlimLoader from '../../SlimLoader';
import { useDebounce, useToggle } from '../../custom-hooks';

const ReviewAndPayItem = ({
  birdBank,
  id,
  quantity,
  images,
  size,
  description,
  color,
  amount,
  updateSignupLineItem,
  deleteSignupLineItem,
  saveSignupLineItem,
  oversized,
  outOfStock,
}) => {
  const [loading, toggleLoading] = useToggle(false);

  const handleSignupLineItemQuantityChange = e => {
    const newQuantity = e.target.value
      ? Number(e.target.value)
      : e.target.value;
    updateSignupLineItem(id, { quantity: newQuantity });
  };

  const isFirstRun = useRef(true);

  const [cachedQuantity, setCachedQuantity] = useState(quantity);
  const debouncedQuantity = useDebounce(quantity, 500);

  const highlightText = e => e.target.select();

  useEffect(() => {
    if (isFirstRun.current) {
      isFirstRun.current = false;
      return;
    }

    if (!quantity) return;

    toggleLoading(true);

    saveSignupLineItem(id, () => toggleLoading(false));
  }, [debouncedQuantity]);

  const oversizedAlert = oversized && (
    <p className="bb-alert-text man">
      <small>Sizes 2XL and above incur an additional $2.00 fee.</small>
    </p>
  );

  useEffect(() => {
    if (quantity) setCachedQuantity(quantity);
  }, [quantity]);

  const handleQuantityBlur = () =>
    updateSignupLineItem(id, { quantity: cachedQuantity });

  const outOfStockAlert = outOfStock ? (
    <em className="bb-alert-text disabled-admin-view">
      Out Of Stock - This item cannot be checked out on and will be removed from
      your bag at checkout.
    </em>
  ) : null;

  const displayFreeShipping = birdBank.individualShip && (
    <span className="label bb-label--notice inline mlm">Shipping Included</span>
  );

  return (
    <div
      className="react-modal-line-item bb-shopping-bag-line-item phn"
      key={id}
    >
      <div className="bb-shopping-bag-image">
        <ItemImage images={images} />
      </div>
      <div className="bb-shopping-bag-description">
        <h4 className="bb-shopping-bag-title truncate-two-line">
          {description}
        </h4>
        <p className="mvn txt-capitalize">{color.toLowerCase()}</p>
        <p className="mvn">{size}</p>
        <div className="pull-right  pts">
          <SlimLoader isActive={loading} />
        </div>
        <div className="flex-rows flex-rows--center-v">
          <span
            className={
              outOfStock
                ? `disabled-view size-quantity-${id}`
                : classNames(
                    'mrs',
                    'bb-qty-field',
                    'form-compact--small',
                    'form-light',
                    'form-light--bordered'
                  )
            }
          >
            <input
              type="number"
              value={quantity}
              onChange={handleSignupLineItemQuantityChange}
              onFocus={highlightText}
              pattern="\d*"
              onBlur={handleQuantityBlur}
            />
          </span>
          <span className="mrs">@</span>
          <span>{amount}</span>
          {displayFreeShipping}
          {outOfStockAlert}
        </div>
        {oversizedAlert}
      </div>
      <button
        className="bb-button-close bb-button-close--red"
        onClick={e => {
          e.preventDefault();
          deleteSignupLineItem(id, () => {
            toggleLoading(false);
          });
        }}
      >
        <span>Remove from Order</span>
      </button>
    </div>
  );
};

export default ReviewAndPayItem;

import React from 'react';

import { Location, DecorationStyle } from '../base-types';
import { labelForDecorationStyle } from '../labels';

function numberToDecorationStyle(n: number): DecorationStyle {
  switch (n) {
    case DecorationStyle.Normal:
      return DecorationStyle.Normal;
    case DecorationStyle.Vintage:
      return DecorationStyle.Vintage;
    case DecorationStyle.PuffPrint:
      return DecorationStyle.PuffPrint;
    case DecorationStyle.SimulatedProcess:
      return DecorationStyle.SimulatedProcess;
    case DecorationStyle.PuffEmbroidery:
      return DecorationStyle.PuffEmbroidery;
    default:
      throw new Error('Unknown decoration style');
  }
}

interface Props
  extends Omit<React.SelectHTMLAttributes<HTMLSelectElement>, 'onChange'> {
  onChange?: (decorationStyle: DecorationStyle) => any;
  readonly?: boolean;
  location: Location;
}

export default function DecorationStyleSelect(props: Props) {
  const { readonly, onChange, location, ...rest } = props;

  const decorationStyles = location.availableDecorationStyles.map(s => {
    const number: number = s;
    const label: string = labelForDecorationStyle(number);
    return [number, label];
  });

  const styleUpdated = (e: React.FocusEvent<HTMLSelectElement>) => {
    const x: number = parseInt(e.target.value);
    const y: DecorationStyle = numberToDecorationStyle(x);
    if (onChange) onChange(y);
  };

  return (
    <>
      {!readonly && (
        <select onChange={styleUpdated} {...rest}>
          {decorationStyles.map(([a, b]) => (
            <option value={a} key={a}>
              {b}
            </option>
          ))}
        </select>
      )}
      {readonly && (
        <div className="label label--default">
          {labelForDecorationStyle(location.decorationStyle)}
        </div>
      )}
    </>
  );
}

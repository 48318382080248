import React from 'react';
import { humanize } from '../utility';

const BagAndTagLabel = props => {
  const { label } = props;
  const { customer, product, marketingInfo } = label;

  const { fullName } = customer;
  const description = product.description;
  const size = `${product.size}`;
  const personalization = product.personalization;

  if (fullName.length > 0 || description.length > 0 || size.length > 0) {
    return (
      <div className="label-content">
        <h3>{fullName}</h3>
        <p className="label-description">{description}</p>
        <p className="label-size">
          <strong>{humanize(size)}</strong>
        </p>
        <p>{personalization}</p>
        <p className="marketing-text">
          <small>{marketingInfo}</small>
        </p>
      </div>
    );
  } else {
    return null;
  }
};

export default BagAndTagLabel;

import React, { useState, useEffect, useCallback, useRef } from 'react';
import Select from 'react-select';
import { localPutForm } from '../fetch-local';
import Loader from './loader';

interface DataValue {
  value: number;
  formatted: string;
}

interface Warehouse {
  id: number;
  label: string;
  supplierId: number;
  inventory?: DataValue;
}

interface Option extends Warehouse {
  value: string;
}

interface OptionProps {
  children: string;
  option: Option;
  className: string;
  isDisabled: boolean;
  isFocused: boolean;
  isSelected: boolean;
  onFocus: (a: Option, any) => any;
  onSelect: (a: Option, any) => any;
}

const WarehouseOption = (props: OptionProps) => {
  const handleMouseDown = e => {
    e.preventDefault();
    e.stopPropagation();
    props.onSelect(props.option, e);
  };

  const handleMouseEnter = e => {
    props.onFocus(props.option, e);
  };

  const handleMouseMove = e => {
    if (props.isFocused) return;
    props.onFocus(props.option, e);
  };

  return (
    <div
      className={props.className + ' warehouse-dropdown-item phm pvs'}
      onMouseDown={handleMouseDown}
      onMouseEnter={handleMouseEnter}
      onMouseMove={handleMouseMove}
      title={props.option.label}
      role="button"
      tabIndex={-1}
    >
      {props.children}
      {props.option.inventory && (
        <span className="txt-success txt-right">
          {props.option.inventory.formatted}
        </span>
      )}
    </div>
  );
};

interface ValueProps {
  children: JSX.Element;
  placeholder: string;
  value: Option;
}

const WarehouseValue = (props: ValueProps) => {
  return (
    <div className="Select-value" title={props.value.label}>
      <span className="Select-value-label">{props.children}</span>
    </div>
  );
};

interface Props {
  orderId: number;
  lineItemId?: number;
  warehouses: Warehouse[];
}

const WarehouseGroupSelect = ({ orderId, lineItemId, ...props }: Props) => {
  const [value, setValue] = useState<string | null>(null);
  const [selectedSupplier, setSelectedSupplier] = useState<string | null>(null);
  const [loading, setLoading] = useState(false);
  const containerRef = useRef<HTMLDivElement>(null);

  const isLineItemLevel: boolean = !!lineItemId;
  const isOrderLevel: boolean = !isLineItemLevel;

  const updateValue = useCallback(
    (value: string) => {
      setValue(value);

      if (!value) return;

      if (isLineItemLevel) {
        window.dispatchEvent(
          new CustomEvent('lineItemWarehouseSet', {
            detail: {
              lineItemId: lineItemId,
              warehouseId: value,
            },
          })
        );
      } else {
        window.dispatchEvent(
          new CustomEvent('orderWarehouseSet', {
            detail: {
              orderId: orderId,
              warehouseId: value,
            },
          })
        );
      }
    },
    [isLineItemLevel, lineItemId, orderId]
  );

  useEffect(() => {
    const handleEvent = e => {
      const warehouse = e.detail.warehouseId.toString();
      if (e.detail.orderId !== orderId) return;
      if (isLineItemLevel) updateValue(warehouse);
    };

    window.addEventListener('orderWarehouseSet', handleEvent);

    return () => {
      window.removeEventListener('orderWarehouseSet', handleEvent);
    };
  }, [lineItemId, orderId, isLineItemLevel, isOrderLevel, updateValue]);

  useEffect(() => {
    const handleEvent = e => {
      const supplier = e.detail.supplierId.toString();
      if (e.detail.orderId !== orderId) return;
      setValue('');
      if (isOrderLevel) setSelectedSupplier(supplier);
    };

    window.addEventListener('orderSupplierSet', handleEvent);

    return () => {
      window.removeEventListener('orderSupplierSet', handleEvent);
    };
  }, [lineItemId, orderId, isLineItemLevel, isOrderLevel, updateValue]);

  useEffect(() => {
    const handleLineItemSupplierSet = e => {
      if (!lineItemId) return;
      if (e.detail.lineItemId !== lineItemId) return;
      const supplier = e.detail.supplierId.toString();
      setValue('');
      setSelectedSupplier(supplier);
    };

    window.addEventListener('lineItemSupplierSet', handleLineItemSupplierSet);

    return () => {
      window.removeEventListener(
        'lineItemSupplierSet',
        handleLineItemSupplierSet
      );
    };
  }, [lineItemId]);

  const warehouses = props.warehouses.filter(
    w => w.supplierId.toString() === selectedSupplier
  );

  return (
    <div className="section txt-left" ref={containerRef}>
      <div className="media" style={{ gap: '1rem' }}>
        <div className="media--obj" style={{ width: '100%' }}>
          <Select
            onChange={e => {
              const value = e ? e.value : e;
              updateValue(value);

              setLoading(true);
              setTimeout(() => {
                if (!containerRef.current) return;
                const form = containerRef.current.closest('form');
                if (!form) return;
                let data = new FormData(form);

                localPutForm(
                  `/orders/purchase_apparel/${orderId}`,
                  data
                ).finally(() => setLoading(false));
              }, 1000);
            }}
            optionComponent={WarehouseOption}
            options={warehouses.map(warehouse => {
              return {
                ...warehouse,
                value: warehouse.id,
                label: warehouse.label,
              };
            })}
            placeholder={'Select Warehouse'}
            value={value}
            valueComponent={WarehouseValue}
            matchPos="start"
          />
        </div>
        <div className="media--obj">{loading && <Loader />}</div>
      </div>
    </div>
  );
};

export default WarehouseGroupSelect;

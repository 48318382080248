import React, { useState, useEffect, useCallback } from 'react';
import Select from 'react-select';
import { localPutJSON } from '../fetch-local';
import Loader from './loader';

interface DataValue {
  value: number;
  formatted: string;
}

interface Warehouse {
  id: number;
  label: string;
  supplierId: number;
  inventory?: DataValue;
}

interface Option extends Warehouse {
  value: string;
}

interface OptionProps {
  children: string;
  option: Option;
  className: string;
  isDisabled: boolean;
  isFocused: boolean;
  isSelected: boolean;
  onFocus: (a: Option, any) => any;
  onSelect: (a: Option, any) => any;
}

const WarehouseOption = (props: OptionProps) => {
  const handleMouseDown = e => {
    e.preventDefault();
    e.stopPropagation();
    props.onSelect(props.option, e);
  };

  const handleMouseEnter = e => {
    props.onFocus(props.option, e);
  };

  const handleMouseMove = e => {
    if (props.isFocused) return;
    props.onFocus(props.option, e);
  };

  return (
    <div
      className={props.className + ' warehouse-dropdown-item phm pvs'}
      onMouseDown={handleMouseDown}
      onMouseEnter={handleMouseEnter}
      onMouseMove={handleMouseMove}
      title={props.option.label}
      role="button"
      tabIndex={-1}
    >
      {props.children}
      {props.option.inventory && (
        <span className="txt-success txt-right">
          {props.option.inventory.formatted}
        </span>
      )}
    </div>
  );
};

interface ValueProps {
  children: JSX.Element;
  placeholder: string;
  value: Option;
}

const WarehouseValue = ({ value, children }: ValueProps) => {
  return (
    <div className="Select-value warehouse-dropdown-item" title={value.label}>
      <span className="Select-value-label">{children}</span>
      {value.inventory && (
        <span className="txt-success txt-right">
          {value.inventory.formatted}
        </span>
      )}
    </div>
  );
};

interface Props {
  name: string;
  orderId: number;
  lineItemId: number;
  lineItemSizeId: number;
  initialSelectedSupplierId: number;
  initialSelectedWarehouseId: number;
  warehouses: Warehouse[];
}

const WarehousesField = ({
  name,
  orderId,
  lineItemId,
  lineItemSizeId,
  initialSelectedSupplierId,
  initialSelectedWarehouseId,
  ...props
}: Props) => {
  const [value, setValue] = useState<string | null>(null);
  const [loading, setLoading] = useState(false);
  const [selectedSupplier, setSelectedSupplier] = useState<string | null>(
    initialSelectedSupplierId ? initialSelectedSupplierId.toString() : null
  );

  const warehouses = props.warehouses.filter(
    w => w.supplierId.toString() === selectedSupplier
  );

  const saveValue = useCallback(
    (value: string | null, persist: boolean = true) => {
      setValue(value);

      if (!persist) return;
      const payload = {
        order: {
          line_items_attributes: {
            0: {
              id: lineItemId,
              line_item_sizes_attributes: {
                0: { id: lineItemSizeId, warehouse_id: value },
              },
            },
          },
        },
      };

      setLoading(true);
      localPutJSON(`/orders/purchase_apparel/${orderId}`, payload).finally(() =>
        setLoading(false)
      );
    },
    [lineItemId, lineItemSizeId, orderId]
  );

  useEffect(() => {
    const handleEvent = e => {
      if (e.detail.orderId.toString() !== orderId.toString()) return;
      saveValue(null, false);
    };

    window.addEventListener('orderSupplierSet', handleEvent);

    return () => {
      window.removeEventListener('orderSupplierSet', handleEvent);
    };
  }, [lineItemId, orderId, saveValue]);

  useEffect(() => {
    const handleLineItemSupplierSet = e => {
      if (!e.detail.lineItemId) return;
      if (e.detail.lineItemId.toString() !== lineItemId.toString()) return;
      saveValue(null, false);
    };

    window.addEventListener('lineItemSupplierSet', handleLineItemSupplierSet);

    return () => {
      window.removeEventListener(
        'lineItemSupplierSet',
        handleLineItemSupplierSet
      );
    };
  }, [lineItemId, saveValue]);

  useEffect(() => {
    const handleEvent = e => {
      if (e.detail.lineItemId) return;
      if (e.detail.orderId.toString() !== orderId.toString()) return;
      saveValue(
        e.detail.warehouseId ? e.detail.warehouseId.toString() : null,
        false
      );
    };

    window.addEventListener('orderWarehouseSet', handleEvent);

    return () => {
      window.removeEventListener('orderWarehouseSet', handleEvent);
    };
  }, [lineItemId, orderId, saveValue]);

  useEffect(() => {
    const handleEvent = e => {
      if (!e.detail.lineItemId) return;
      if (e.detail.lineItemId.toString() !== lineItemId.toString()) return;
      saveValue(
        e.detail.warehouseId ? e.detail.warehouseId.toString() : null,
        false
      );
    };

    window.addEventListener('lineItemWarehouseSet', handleEvent);

    return () => {
      window.removeEventListener('lineItemWarehouseSet', handleEvent);
    };
  }, [lineItemId, saveValue]);

  useEffect(() => {
    const eventName = 'lineItemSizeSupplierSet';

    const handleEvent = e => {
      if (e.detail.lineItemSizeId.toString() !== lineItemSizeId.toString())
        return;

      const supplierId = e.detail.supplierId
        ? e.detail.supplierId.toString()
        : null;

      setSelectedSupplier(supplierId);

      saveValue(null, false);
    };

    window.addEventListener(eventName, handleEvent);

    setValue(
      initialSelectedWarehouseId ? initialSelectedWarehouseId.toString() : null
    );
    return () => {
      window.removeEventListener(eventName, handleEvent);
    };
  }, [initialSelectedWarehouseId, saveValue, lineItemSizeId, props.warehouses]);

  return (
    <div className="section txt-left">
      <div className="media" style={{ gap: '1rem' }}>
        <div className="media--obj" style={{ width: '100%' }}>
          <Select
            onChange={e => {
              const value = e ? e.value : e;
              saveValue(value);
            }}
            optionComponent={WarehouseOption}
            options={warehouses.map(warehouse => {
              return {
                ...warehouse,
                value: warehouse.id,
                label: warehouse.label,
              };
            })}
            placeholder={'Select Warehouse'}
            name={name}
            value={value}
            valueComponent={WarehouseValue}
            matchPos="start"
          />
        </div>
        <div className="media--obj">{loading && <Loader />}</div>
      </div>
    </div>
  );
};

export default WarehousesField;

import React, { useEffect, useState, useMemo, useCallback } from 'react';
import ItemGroup from './ItemGroup';
import ContactInfo from '../ContactInfo';
import ShoppingBagModal from './ShoppingBagModal';
import { useToggle } from '../custom-hooks';
import Checkout from './Checkout/Checkout';
import {
  localGet,
  localPutJSON,
  localPostJSON,
  localDelete,
  extractErrorMessage,
} from '../../fetch-local';
import { find, flatMap } from 'lodash';
import classNames from 'classnames';
import { formatMoney } from '../../utility';
import useInterval from '../useInterval';
import Countdown from '../Countdown';
import FormatTextWithLineBreaks from '../FormatTextWithLineBreaks';

export default function SignupManager(props) {
  const {
    birdBank,
    schoolOptions,
    organizationOptions,
    graduationYearOptions,
    chapterPositionOptions,
    editable,
  } = props;
  const [signup, setSignup] = useState(props.signup);
  const [bagIsOpen, toggleBag] = useState(
    !!props.signup.id && signup.signupLineItems.length > 0
  );
  const [failedToSave, toggleFailedToSave] = useToggle(false);
  const [errorMessage, setErrorMessage] = useState();
  const [checkoutIsOpen, toggleCheckout] = useToggle(false);
  const [animate, toggleAnimate] = useToggle(false);
  const [itemCountWas, setItemCountWas] = useState(
    signup.signupLineItems.length
  );
  const [isRunning, setRunning] = useState(bagIsOpen || checkoutIsOpen);
  let showPath = signup && signup.links && signup.links.showPath;
  let showPathJson = signup && signup.links && signup.links.showPathJson;
  const { individualShip, pickupDetails } = birdBank;

  useInterval(
    () => {
      localGet(showPathJson).then(data => {
        setSignup(data.signup);
      });
    },
    isRunning ? 5000 : null
  );

  useEffect(() => {
    const newItemCount = signup.signupLineItems.length;
    if (newItemCount > itemCountWas) {
      toggleAnimate(true);
      setTimeout(() => toggleAnimate(false), 200);
    }
    setItemCountWas(newItemCount);
  }, [signup.signupLineItems.length]);

  const openCheckout = () => {
    signup.signupLineItems.forEach(signupLineItem => {
      if (!signupLineItem.outOfStock) return;
      deleteSignupLineItem(signupLineItem.id);
    });

    toggleCheckout(true);
    setRunning(true);
    toggleBag(false);
  };

  const openShoppingBag = e => {
    e.preventDefault();
    toggleBag(true);
    setRunning(true);
  };

  const birdBankItems = useMemo(
    () => flatMap(birdBank.groupedItems, ({ items }) => items),
    [birdBank.groupedItems]
  );

  const itemGroups = birdBank.groupedItems.map(({ description, items }, i) => (
    <ItemGroup
      key={i}
      name={description}
      items={items}
      signup={signup}
      birdBank={birdBank}
      setSignup={setSignup}
      enabled={birdBank.status != 'pending'}
    />
  ));

  const organizerInfo = birdBank.isOrganizer ? null : (
    <ContactInfo
      title={'Organizer'}
      name={birdBank.organizerName}
      email={birdBank.organizerEmail}
    />
  );

  const contactInfo = (
    <React.Fragment>
      <ContactInfo
        title={
          birdBank.nationalOrder ? 'Live Sales Coordinator' : 'Campus Manager'
        }
        name={birdBank.campusManagerName}
        email={birdBank.campusManagerEmail}
      />
      {organizerInfo}
    </React.Fragment>
  );

  const organizationId = birdBank.nationalOrder
    ? birdBank.organizationId
    : signup.organizationId;

  const updateSignup = useCallback(signup => {
    setSignup(previousSignup => ({
      ...previousSignup,
      ...signup,
      dirty: true,
    }));
    setRunning(false);
  }, []);

  const updateAnswer = (questionId, newAnswer) => {
    setSignup(previousSignup => {
      const answers = previousSignup.answers.map(answer => {
        if (questionId === answer.questionId)
          return { ...answer, answer: newAnswer };
        else return answer;
      });

      return { ...previousSignup, answers };
    });
    setRunning(false);
  };

  const saveSignup = (afterSave, onFailure) => {
    localPostJSON(`${signup.links.createPath}.json`, {
      check_stamp: signup.updatedAt,
      shopping_bag: {
        signup: {
          first_name: signup.firstName,
          last_name: signup.lastName,
          email: signup.email,
          phone: signup.phone,
          address: signup.address ? signup.address.trim() : '',
          address_two: signup.addressTwo ? signup.addressTwo.trim() : '',
          city: signup.city ? signup.city.trim() : '',
          state: signup.state ? signup.state.trim() : '',
          zip: signup.zip ? signup.zip.trim() : '',
          no_charge: signup.noCharge,
          organization_id: organizationId,
          organization_name_if_other: signup.organizationNameIfOther,
          school_id: signup.schoolId,
          school_name_if_other: signup.schoolNameIfOther,
          graduation_year: signup.graduationYear,
          chapter_position: signup.chapterPosition,
          answers_attributes: signup.answers.map(
            ({ id, questionId, answer }) => ({
              id,
              answer,
              question_id: questionId,
            })
          ),
        },
      },
    })
      .then(data => {
        setSignup(previousSignup => ({
          ...previousSignup,
          ...data.signup,
          dirty: false,
        }));
        setErrorMessage(null);
        toggleFailedToSave(false);
        setRunning(true);
      })
      .then(afterSave)
      .catch(error => {
        onFailure && onFailure();
        extractErrorMessage(error, 'Unknown Error').then(message => {
          setErrorMessage(message);
        });
        toggleFailedToSave(true);
        setRunning(false);
      });
  };

  const updateSignupLineItem = (id, changes) => {
    setSignup(previousSignup => {
      const signupLineItems = previousSignup.signupLineItems.map(
        signupLineItem => {
          if (signupLineItem.id === id)
            return { ...signupLineItem, ...changes };
          else return signupLineItem;
        }
      );
      return { ...previousSignup, signupLineItems };
    });
    setRunning(false);
  };

  const saveSignupLineItem = (id, onSuccess) => {
    const signupLineItem = find(signup.signupLineItems, { id });
    const {
      links,
      birdBankItemId,
      quantity,
      personalizations,
      answers,
    } = signupLineItem;

    localPutJSON(links.updatePath, {
      signup_line_item: {
        bird_bank_item_id: birdBankItemId,
        quantity: quantity,
        answers_attributes: answers.map(({ id, answer, questionId }) => ({
          id,
          answer,
          question_id: questionId,
        })),
        personalizations_attributes: personalizations.map(
          ({ id, name, number, locationId }) => ({
            id,
            name,
            number,
            location_id: locationId,
          })
        ),
      },
    })
      .then(data => {
        setSignup(previousSignup => ({ ...previousSignup, ...data.signup }));
        setErrorMessage(null);
        toggleFailedToSave(false);
        setRunning(true);
        if (onSuccess) onSuccess();
      })
      .catch(error => {
        onSuccess();
        setRunning(false);
        extractErrorMessage(error, 'Unknown Error').then(message => {
          setErrorMessage(message);
        });
        toggleFailedToSave(true);
      });
  };

  const deleteSignupLineItem = (id, onSuccess) => {
    const signupLineItem = find(signup.signupLineItems, { id });
    const { deletePath } = signupLineItem.links;

    localDelete(deletePath, {
      signup_line_item: { id },
    })
      .then(data => {
        setSignup(previousSignup => ({ ...previousSignup, ...data.signup }));
        if (onSuccess) onSuccess();
        setErrorMessage(null);
        toggleFailedToSave(false);
      })
      .catch(error => {
        if (onSuccess) onSuccess();
        extractErrorMessage(error, 'Unknown Error').then(message => {
          setErrorMessage(message);
        });
        toggleFailedToSave(true);
      });
  };

  const displayDescription = birdBank.description && (
    <div>
      <h3 className="bb-item-headline mtn">Details</h3>
      <FormatTextWithLineBreaks text={birdBank.description} />
    </div>
  );

  const displayPickupDetails = !individualShip && pickupDetails && (
    <div className="mtl">
      <h3 className="bb-item-headline mbn">Pickup/Delivery Details</h3>
      {<FormatTextWithLineBreaks text={pickupDetails} />}
    </div>
  );

  const checkout = checkoutIsOpen && (
    <Checkout
      failedToSave={failedToSave}
      errorMessage={errorMessage}
      birdBank={birdBank}
      signup={signup}
      modalIsOpen={checkoutIsOpen}
      onClose={() => {
        toggleCheckout(false);
        setRunning(false);
      }}
      onChange={updateSignup}
      onSubmitStep={saveSignup}
      schoolOptions={schoolOptions}
      organizationOptions={organizationOptions}
      graduationYearOptions={graduationYearOptions}
      chapterPositionOptions={chapterPositionOptions}
      onChangeSignupLineItem={updateSignupLineItem}
      saveSignupLineItem={saveSignupLineItem}
      onDeleteSignupLineItem={deleteSignupLineItem}
      updateAnswer={updateAnswer}
      editable={editable}
    />
  );

  return (
    <React.Fragment>
      <div className="bb-notification bb-notification--info">
        <p className="mbs">
          <strong>Hurry!</strong> This sale ends on:{' '}
          {`${birdBank.endOfProject}`}
        </p>
        <Countdown birdBank={birdBank} />
      </div>
      <div className="bb-l-container">
        <h2 className="bb-headline">{birdBank.name}</h2>
        <div className="bb-container">
          <div className="bb-container__main">
            <div className="bb-grid">{itemGroups}</div>
          </div>
          <div className="bb-container__sidebar">
            <div className="bb-container__sidebar-item">
              {displayDescription}
              {displayPickupDetails}
            </div>
            <div className="bb-container__sidebar-item bb-callout bb-callout--bordered">
              {contactInfo}
            </div>
            <p>
              <a
                href="https://universitytees.com/faqs"
                rel="noopener noreferrer"
                target="_blank"
              >
                Frequently Asked Questions
              </a>
            </p>
          </div>
        </div>
        <button
          className={classNames('bb-shopping-icon', {
            'bb-shopping-icon--animate': animate,
          })}
          type="button"
          onClick={openShoppingBag}
        >
          <span className="bb-shopping-count">
            {`${signup.signupLineItems.length}`}
          </span>
          <span className="bb-shopping-text">
            Total:
            <strong>{formatMoney(`${signup.shoppingBagSubtotal}`)}</strong>
          </span>
        </button>

        <ShoppingBagModal
          birdBank={birdBank}
          total={signup.shoppingBagSubtotal}
          birdBankItems={birdBankItems}
          items={signup.signupLineItems}
          isOpen={bagIsOpen}
          closeModal={() => {
            toggleBag(false);
            setRunning(false);
          }}
          onCheckoutButtonClick={openCheckout}
          onChangeSignupLineItem={updateSignupLineItem}
          saveSignupLineItem={saveSignupLineItem}
          onDeleteSignupLineItem={deleteSignupLineItem}
          failedToSave={failedToSave}
          errorMessage={errorMessage}
          minimumNotMetForAnAttachedOrder={
            birdBank.minimumNotMetForAnAttachedOrder
          }
          orderQuantitiesArray={birdBank.orderQuantitiesArray}
          isCollegiate={birdBank.isCollegiate}
        />

        {checkout}
      </div>
    </React.Fragment>
  );
}
